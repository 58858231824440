import { createApp, reactive } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { upperFirst, camelCase } from "lodash";

const GStore = reactive({ flashMessage: "" });
const CookieConsent = reactive({ consent: "" });

const app = createApp(App)
  .use(store)
  .use(router)
  .provide("CookieConsent", CookieConsent)
  .provide("GStore", GStore);

// Check that service workers are supported
if ("serviceWorker" in navigator) {
  // Use the window load event to keep the page load performant
  window.addEventListener("load", () => {
    navigator.serviceWorker.register("/service-worker.js");
  });
}

// Automatic register base/icon components
const requireComponent = require.context(
  "./components/base",
  true,
  /[A-Z]\w+\.(vue|js)$/
);
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(
      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    )
  );
  app.component(componentName, componentConfig.default || componentConfig);
});

app.mount("#app");
